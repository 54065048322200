<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-else>
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="togglePreviewModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-form lazy-validation ref="volunteerForm">
            <div>
              <img height="200" width="200" :src="resourceData.document" />

              <!-- <input type="file" @change="previewFile" />
    <div v-if="filePreviewUrl">
      <img v-if="isImage" :src="filePreviewUrl" alt="File Preview" />
      <video v-else :src="filePreviewUrl" controls></video>
    </div>-->
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end" v-if="this.type === 'add'">
          <v-btn
            class="dialogAction-btnText text-capitalize px-7"
            height="45"
            color="#38227A"
            :loading="formLoading"
          >
            <span>Add Note</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import { NOTE_SECTION_POST_API } from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "PreviewModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      formLoading: false,
      note: "",
      note_by: "",
      user_id: localStorage.getItem("user_id"),
      filePreviewUrl: "",
      isImage: false,
      resourceData: {},
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getPreviewShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.togglePreviewModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.previewModal.type;
    },
    dialogTitle() {
      if (this.type === "add") return "Add Note";
      else return "Preview";
    },
    actionText() {
      if (this.type === "add") {
        return "Add Note";
      } else {
        return "Save";
      }
    },
    toastMessage() {
      if (this.type === "add") {
        return "Note Added Successfully.";
      } else {
        return "Volunteer Detail Updated.";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      togglePreviewModal: "gameManagement/togglePreviewModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getDetail();
    },
    closeModal() {
      this.loading = false;
      this.formLoading = false;
      this.name = "";
      this.email = "";
      this.mobile = null;
      this.areaOfFocus = null;
      this.areaOfFocusList = [];
    },
    previewFile(event) {
      //alert("hi")
      const file = event.target.files[0];
      if (file) {
        // alert(file)
        this.isImage = file.type.startsWith("image/");
        const reader = new FileReader();
        reader.onload = () => {
          this.filePreviewUrl = reader.result;
          //  alert( this.filePreviewUrl)
        };
        //  alert(reader.readAsDataURL(file));
        reader.readAsDataURL(file);
      }
    },

    getDetail() {
      const successHandler = (res) => {
        console.log(res.data);
        this.resourceData = res.data;
        // alert(this.resourceData.document)
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      // alert(this.$store.state.gameManagement.previewModal.resourceID)
      formData["id"] = this.$store.state.gameManagement.previewModal.resourceID;
      Axios.request_GET(
        "/game/game_resource/" +
          this.$store.state.gameManagement.previewModal.resourceID +
          "/",
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message: this.toastMessage,
          color: "s",
        });
        this.$emit("reload");
        location.reload();
        this.toggleNoteModal({ show: false });
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
        this.showToast({
          message: res,
          color: "e",
        });
      };
      let formData = {};
      formData["game"] = this.$route.query.game_id;
      console.log(formData, this.selectGameData);
      formData["note"] = this.note;
      formData["note_by"] = this.user_id;
      if (this.type === "add") {
        Axios.request_POST(
          NOTE_SECTION_POST_API,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
